import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { AllAppsPage } from 'src/components/AllAppsPage';

const Index = (): JSX.Element => {
  return <AllAppsPage />;
};

export async function getStaticProps({ locale }: any): Promise<any> {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

export default Index;
