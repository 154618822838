import React from 'react';

import kebabCase from 'lodash/kebabCase';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import AccessUnavailable from '@assets/Icons/AllApps/AccessUnavailable.svg';
import Empty from '@components/elements/Empty';
import { useModal } from '@hooks';
import { KOIREADER_SUPPORT_EMAIL } from 'src/global/constants';
import { AppGroup } from 'src/global/types';

import {
  StyledAllAppsList,
  StyledApp,
  StyledAppsSection,
} from './styledComponents';

interface AllAppsListProps {
  appGroups: AppGroup[];
  searchValue: string;
}

// All apps container component
const AllAppsList = ({ appGroups, searchValue }: AllAppsListProps) => {
  const { t: common } = useTranslation();
  const { modal } = useModal();

  // Only home tab has multiple app groups
  const isHomeTab = appGroups.length > 1;

  if (appGroups.length === 0) return <></>;

  const filteredAppGroups = appGroups.filter((group) =>
    group.appsDetails.some((app) =>
      app.name.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  return (
    <StyledAllAppsList>
      {filteredAppGroups.length > 0 ? (
        filteredAppGroups.map((group) => {
          return (
            <StyledAppsSection key={group.title}>
              {isHomeTab && (
                <div className="heading">
                  {group.icon}
                  <h1>{group.title}</h1>
                </div>
              )}
              <div className="apps">
                {group.appsDetails
                  .filter((app) =>
                    app.name.toLowerCase().includes(searchValue.toLowerCase())
                  )
                  .map((app) => {
                    return app.isSubscribed ? (
                      <Link
                        href={`/apps/${kebabCase(app.id)}?tab=home`}
                        key={app.id}
                        passHref
                        legacyBehavior
                      >
                        <StyledApp>
                          {app.icon}
                          <span>{app.name}</span>
                        </StyledApp>
                      </Link>
                    ) : (
                      <StyledApp
                        key={app.id}
                        onClick={() =>
                          modal({
                            title: common('info.getAppAccess'),
                            type: 'warning',
                            okText: 'Contact Support',
                            cancelText: 'Cancel',
                            centered: true,
                            icon: <AccessUnavailable />,
                            onOk: () => {
                              window.location.href = `mailto:${KOIREADER_SUPPORT_EMAIL}`;
                            },
                            onCancel: () => null,
                          })
                        }
                      >
                        {app.icon}
                        <span>{app.name}</span>
                      </StyledApp>
                    );
                  })}
              </div>
            </StyledAppsSection>
          );
        })
      ) : (
        <Empty description="No apps available" />
      )}
    </StyledAllAppsList>
  );
};

export default AllAppsList;
